<template>
  <div>
    <ListaApps></ListaApps>
  </div>

</template>

<script>
import BaseView from "@/template/BaseView.vue";
import ListaApps from "@/components/Apps/ListaApps.vue";

export default {
  components: {
    BaseView,
    ListaApps,
  },
  created() {
    if (this.$store.getters.user.user.level == 'blogger') {this.$router.push("/dynamicRoute/AccessDenied")};
  },
};
</script>
