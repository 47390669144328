<template>
  <div class="container">
    <BaseHeader title="Aplicativos" :navigation="navigation"> </BaseHeader>
    <div class="mb-4">
      <div class="grid-aplicativos">
        <div class="item">
          <div class="header">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 1L7.75 9.25M16 1L10.75 16L7.75 9.25M16 1L1 6.25L7.75 9.25"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>Broadcast</p>
          </div>
          <div class="text">
            Dispare e-mails personalizados para sua base de leads.
          </div>

          <div class="footer">
            <router-link :to="'apps/broadcast'">
              <div class="access">
                <p>Acessar</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </router-link>
          </div>
        </div>
        <div
          class="item gwhats-item"
          :class="$store.getters.recursos.nwhats ? '' : 'blocked'"
        >
          <div class="header">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 8.55557C17.003 9.72878 16.7289 10.8861 16.2 11.9333C15.5728 13.1882 14.6086 14.2437 13.4155 14.9816C12.2223 15.7195 10.8473 16.1106 9.44443 16.1111C8.27122 16.1142 7.11387 15.8401 6.06666 15.3111L1 17L2.68889 11.9333C2.15994 10.8861 1.88583 9.72878 1.88889 8.55557C1.88943 7.15269 2.28054 5.77766 3.01841 4.58451C3.75629 3.39135 4.81178 2.42719 6.06666 1.80002C7.11387 1.27107 8.27122 0.996966 9.44443 1.00003H9.88888C11.7416 1.10224 13.4916 1.88426 14.8037 3.19634C16.1157 4.50843 16.8978 6.25837 17 8.11113V8.55557Z"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.2099 10.9856L13.2532 12.1892C13.2577 12.3009 13.2387 12.4123 13.1976 12.5163C13.1564 12.6203 13.0939 12.7146 13.0142 12.7931C12.9344 12.8716 12.8391 12.9326 12.7343 12.9722C12.6295 13.0118 12.5176 13.0291 12.4058 13.0231C11.164 12.9334 9.96065 12.5543 8.8924 11.9163C7.89899 11.3352 7.04598 10.5431 6.39394 9.59594C5.67693 8.57378 5.21129 7.3974 5.03473 6.16209C5.02069 6.05152 5.02988 5.93923 5.06171 5.83238C5.09354 5.72553 5.14731 5.62647 5.21959 5.54149C5.29188 5.45652 5.38111 5.38749 5.48158 5.33881C5.58206 5.29013 5.69159 5.26286 5.8032 5.25874L7.00913 5.21535C7.20415 5.20642 7.39575 5.26856 7.54823 5.39019C7.70072 5.51183 7.80367 5.68466 7.83791 5.87646C7.90267 6.25979 8.01067 6.63457 8.15986 6.99366C8.21911 7.13531 8.23642 7.29094 8.20976 7.44212C8.1831 7.5933 8.11357 7.73369 8.00942 7.84666L7.51724 8.37453C8.12561 9.35831 8.98879 10.1599 10.0157 10.6948L10.5079 10.167C10.6133 10.0552 10.7486 9.97588 10.8978 9.93848C11.047 9.90107 11.2037 9.90713 11.3496 9.95593C11.7192 10.0786 12.1015 10.1592 12.4892 10.1961C12.6855 10.2166 12.8673 10.3083 13.0002 10.4539C13.1331 10.5995 13.2077 10.7887 13.2099 10.9856Z"
                fill="#4ea934"
              />
            </svg>
            <p>Greenn Message</p>
          </div>
          <div class="text">Automatize seu WhatsApp e aumente suas vendas.</div>

          <div class="footer">
            <div>
            <!-- HIDDEN-FEATURE -->
            <!-- <component :is="
              ($store.getters.user.user.level == 'owner' || $store.getters.user.user.level == 'admin' || $store.getters.user.user.level == 'commercial_leader') && $store.getters.recursos.nwhats ? 'router-link' : 'div'"  @click="$store.getters.recursos.nwhats ? ($store.getters.user.user.level == 'owner' || $store.getters.user.user.level == 'admin' || $store.getters.user.user.level == 'commercial_leader' ? '' : confirmGWhats()) : ''" :to="$store.getters.recursos.nwhats ? 'estrategico/log-messages' : ''"> -->
              <div 
                tabindex="0"
                class="access"
              > 
                <p>Download</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <a href="https://chromewebstore.google.com/detail/g-whats/oeahpmkoganhdmmnpdjplleookmlnlda" target="_blank">
                Extensão
              </a>
              <component
                v-b-tooltip.hover.bottom
                title="Aplicativo G Whats não terá mais atualizações!"
                tabindex="0"
                :is="hasAccessHistoryMessages ? 'router-link' : 'div'"  
                :to="hasAccessHistoryMessages ? 'estrategico/log-messages' : ''"
                @click="hasAccessHistoryMessages ? '' : confirmGWhats()" 
              >
                Aplicativo ⚠️
              </component>
            </div>
            <!-- </component> -->
          </div>
        </div>
        <div class="item" v-if="$store.getters.user.user.level == 'seller'">
          <div class="header">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#4ea934"
              viewBox="0 0 256 256"
            >
              <path
                d="M140,80v41.21l34.17,20.5a12,12,0,1,1-12.34,20.58l-40-24A12,12,0,0,1,116,128V80a12,12,0,0,1,24,0ZM128,28A99.38,99.38,0,0,0,57.24,57.34c-4.69,4.74-9,9.37-13.24,14V64a12,12,0,0,0-24,0v40a12,12,0,0,0,12,12H72a12,12,0,0,0,0-24H57.77C63,86,68.37,80.22,74.26,74.26a76,76,0,1,1,1.58,109,12,12,0,0,0-16.48,17.46A100,100,0,1,0,128,28Z"
              ></path>
            </svg>

            <p>Histórico de CRM</p>
          </div>
          <div class="text">
            Acompanhe as movimentações e a quantidade de leads do CRM.
          </div>
          <div class="footer" @click="$router.push({ name: 'relatorio-crm' })">
            <div class="access">
              <p>Acessar</p>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6H11M11 6L6 1M11 6L6 11"
                  stroke="#4ea934"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="item"
          v-if="this.$store.getters.user.user.level !== 'seller'"
        >
          <div class="header">
            <svg
              width="12"
              height="21"
              viewBox="0 0 12 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1H8.27273C7.0672 1 5.91104 1.50044 5.05861 2.39124C4.20617 3.28204 3.72727 4.49022 3.72727 5.75V8.6H1V12.4H3.72727V20H7.36364V12.4H10.0909L11 8.6H7.36364V5.75C7.36364 5.49804 7.45942 5.25641 7.6299 5.07825C7.80039 4.90009 8.03162 4.8 8.27273 4.8H11V1Z"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p>Lead Ads</p>
          </div>
          <div class="text">
            Faça gestão das suas página e capture mais leads.
          </div>

          <div class="footer">
            <router-link :to="'apps/lead-ads'">
              <div class="access">
                <p>Acessar</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </router-link>
          </div>
        </div>
        <div
          class="item"
          v-if="$store.getters.user.user.level !== 'seller' || sellerTags"
        >
          <div class="header">
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.43364 5.69568H5.44251M17.4843 11.7155L11.1264 18.4491C10.9617 18.6238 10.7661 18.7623 10.5509 18.8568C10.3356 18.9513 10.1048 19 9.87172 19C9.63866 19 9.40789 18.9513 9.19259 18.8568C8.9773 18.7623 8.78171 18.6238 8.617 18.4491L1 10.3914V1H9.86729L17.4843 9.06717C17.8146 9.41909 18 9.89514 18 10.3914C18 10.8876 17.8146 11.3636 17.4843 11.7155Z"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>Gestão de tags</p>
          </div>
          <div class="text">
            Gerencie suas tags para categorizar seus leads.
          </div>

          <div class="footer">
            <router-link :to="'apps/tags'">
              <div class="access">
                <p>Acessar</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </router-link>
          </div>
        </div>
        <div
          class="item"
          v-if="this.$store.getters.user.user.level !== 'seller'"
        >
          <div class="header">
            <svg
              width="17"
              height="21"
              viewBox="0 0 17 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.33333 1L1 12.4H8.5L7.66667 20L16 8.6H8.5L9.33333 1Z"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p>Automação de vendedores</p>
          </div>
          <div class="text">
            Gerencie sua distribuição de leads para seus vendedores.
          </div>

          <div class="footer">
            <div class="access" @click="openModal('Modal-Automacao')">
              <p>Acessar</p>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6H11M11 6L6 1M11 6L6 11"
                  stroke="#4ea934"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="item"
          v-if="
            $store.getters.user.user.level == 'owner' ||
            $store.getters.user.user.level == 'admin' ||
            $store.getters.user.user.level == 'commercial_leader'
          "
        >
          <div class="header">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 18L22 12L16 6"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 6L2 12L8 18"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p>Pixel</p>
          </div>
          <div class="text">
            Gerencie seus pixels para depois seguimentar suas página.
          </div>

          <div class="footer">
            <router-link :to="'apps/pixel'">
              <div class="access">
                <p>Acessar</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </router-link>
          </div>
        </div>
        <div
          class="item"
          v-if="$store.getters.user.user.level == 'owner' ||
            $store.getters.user.user.level == 'admin' ||
            $store.getters.user.user.level == 'commercial_leader'
          "
        >
          <div class="header">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.59 13.51L15.42 17.49"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.41 6.51001L8.59 10.49"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p>Integrações</p>
          </div>
          <div class="text">
            Realize suas integrações externas e capture mais leads.
          </div>

          <div class="footer">
            <router-link :to="'apps/integracoes'">
              <div class="access">
                <p>Acessar</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </router-link>
          </div>
        </div>
        <!-- HIDDEN-FEATURE -->
        <div
          class="item hidden"
          :class="$store.getters.recursos.ncalendar ? '' : 'blocked'"
          v-if="$store.getters.user.user.level != 'blogger'"
        >
          <div class="header">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3 10H21"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16 2V6"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 2V6"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p>Agendamentos</p>
          </div>
          <div class="text">
            Gerencie sua agenda e crie seu agendamento personalizado.
          </div>

          <div class="footer">
            <!-- HIDDEN-FEATURE -->
            <!-- <component :is="$store.getters.recursos.ncalendar ? 'router-link' : 'div'" :to="$store.getters.recursos.ncalendar ? 'apps/agendamentos' : ''"> -->
            <div>
              <div class="access">
                <p>Acessar</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <!-- HIDDEN-FEATURE -->
            <!-- </component> -->
          </div>
        </div>
      </div>
      <ModalAutomocao />
    </div>
  </div>
</template>
<script>
// AUTOMAÇÂO DE VENDEDOR
import ModalAutomocao from "@/components/Apps/AutomocaoVendedor/ModalAutomocao";
import Vue from "vue";
import BaseHeader from "@/components/BaseHeader";
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();
import GlobalSettingsService from "@/services/resources/GlobalSettingsService";
const serviceGlobalSettings = GlobalSettingsService.build();
//
export default {
  name: "ListaMidias",
  components: {
    BaseHeader,
    ModalAutomocao,
  },
  data() {
    return {
      navigation: [{ link: "Aplicativos", to: this.$route.fullPath }],
      client: {
        width: 0,
      },
      sellerTags: false,
      hasAccessHistoryMessages: false,
    };
  },
  methods: {
    openModal(data, fluxo) {
      this.$bvModal.show(data);
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    confirmGWhats() {
      document.activeElement.blur();
      Vue.swal({
        title: "G Whats",
        text: `Você deseja realizar o download do aplicativo do G Whats em seu dispositivo?`,
        // type: "danger",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var url =
            "https://gdigital.sfo2.cdn.digitaloceanspaces.com/whats2/gwhats.exe";
          if (navigator.platform.indexOf("Mac") !== -1) {
            url =
              "https://gdigital.sfo2.cdn.digitaloceanspaces.com/whats2/gwhats.zip";
          }
          // Verificar se o sistema operacional é Linux
          else if (navigator.platform.indexOf("Linux") !== -1) {
            url =
              "https://gdigital.sfo2.cdn.digitaloceanspaces.com/whats2/gwhats.deb";
          }
          window.open(url, "_blank");
        }
      });
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    serviceGlobalSettings
      .read({id: "access_save_msg_pannel"})
      .then((resp) => {
        if(!resp.length){
          return;
        }
        let respArray = resp.meta_value.split(',');
        this.hasAccessHistoryMessages = respArray.includes(`${this.$store.getters.user.user.tenant_id}`)
      })
    if (this.$store.getters.user.user.level == 'seller') {
      serviceSettings
        .read({id: 'seller'})
        .then((resp) => {
          this.sellerTags = resp ? resp.find((item) => item.meta_key == 'change_tag').meta_value : true
        })
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
};
</script>

<style lang="scss" scoped>
.gwhats-item .footer {
  width: 100%;
  overflow: hidden;
  >:first-child {
    width: 200%;
    min-width: 200%;
    display: flex;
    >:first-child {
      width: 50%;
      margin: 0;
      transition: .3s;
    }
    >:not(:first-child) {
      width: calc(25% - 8px);
      border-radius: 10px;
      border: 1px solid var(--white-medium);
      text-align: center;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--gray05);
      transition: .3s;
      cursor: pointer;
      &:hover {
        background-color: var(--greenn2);
        border-color: var(--greenn);
        color: var(--greenn);
      }
    }
    >:last-child {
      margin-right: 0;
    }
  }
  &:focus-within >:first-child >:first-child {
    margin-left: -50%;
  }
}
.news {
  position: relative;
  &::before {
    position: absolute;
    top: 15px;
    right: -10px;
    width: 100px !important;
    padding: 0 !important;
    height: 30px;
    background: var(--greenn);
    content: "Novidade!" !important;
    max-width: 100px !important;
    color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 5px 0 10px;
    font-size: 13px;
  }
  &::after {
    content: "";
    background-color: var(--greenn-dark);
    width: 10px;
    height: 10px;
    clip-path: polygon(0% 0%, 100% 0%, 0 100%);
    position: absolute;
    top: 45px;
    right: -10px;
  }
}
.blocked {
  position: relative;
  background-color: var(--white-light);
  cursor: not-allowed;
  user-select: none;
  -webkit-user-drag: none;
  * {
    cursor: not-allowed !important;
    user-select: none;
    -webkit-user-drag: none;
  }
  &:hover::before {
    max-width: 150px;
    width: 150px;
    padding-right: 10px;
    color: white !important;
  }
  &::before {
    position: absolute;
    top: 15px;
    right: -10px;
    max-width: 35px;
    width: 35px;
    height: 35px;
    transition: all 0.5s;
    padding-left: 35px;
    background-color: var(--greenn);
    line-height: 1;
    color: #ffffff00 !important;
    white-space: pre;
    content: "Não incluso\A em seu plano";
    overflow: hidden;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23ffffff" viewBox="0 0 256 256"><path stroke="%23ffffff" stroke-width="5" d="M208,80H176V56a48,48,0,0,0-96,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM96,56a32,32,0,0,1,64,0V80H96ZM208,208H48V96H208V208Z"></path></svg>');
    background-position: 8px center;
    background-repeat: no-repeat;
    background-size: 20px;
    color: var(--greenn);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 5px 0 10px;
    font-size: 13px;
  }
  &::after {
    content: "";
    background-color: var(--greenn-dark);
    width: 10px;
    height: 10px;
    clip-path: polygon(0% 0%, 100% 0%, 0 100%);
    position: absolute;
    top: 50px;
    right: -10px;
  }
}
.hidden {
  position: relative;
  background: var(--white-light);
  cursor: not-allowed;
  user-select: none;
  -webkit-user-drag: none;
  * {
    cursor: not-allowed !important;
    user-select: none;
    -webkit-user-drag: none;
  }
  &::before {
    position: absolute;
    top: 15px;
    right: -10px;
    width: 100px !important;
    padding: 0 !important;
    height: 30px;
    background: var(--greenn);
    content: "Em breve!" !important;
    max-width: 100px !important;
    color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 5px 0 10px;
    font-size: 13px;
  }
  &::after {
    content: "";
    background-color: var(--greenn-dark);
    width: 10px;
    height: 10px;
    clip-path: polygon(0% 0%, 100% 0%, 0 100%);
    position: absolute;
    top: 45px;
    right: -10px;
  }
}
a {
  text-decoration: none;
}
.apps-h1 {
  text-align: center;
  margin: 30px 0;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 5px;
}
.apps-logo {
  width: 100%;
  height: 100px;
  transform: scale(5);
}
.container-table {
  display: flex !important;
  justify-content: center !important;
}
.apps-title {
  position: relative;
  top: 1rem;
  font-size: 14px;
  color: #81858e;
  letter-spacing: 3px;
  // white-space: nowrap;
}
.card-apps {
  overflow: hidden;
  display: grid;
  justify-content: center;
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  padding: 45px;
  cursor: pointer;
  transition: all 0.2s;
  background: #f7f7f7;
  &:hover {
    background: #e7e7e7a2;
  }
}
.apps {
  text-align: center;
  position: relative;
}

.container-appss {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px;
}
.container-apps {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 45px;
}

.grid-aplicativos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  .item {
    width: 100%;
    border: var(--white-medium) solid 1px;
    border-radius: 10px;
    padding: 25px 20px;
    .footer {
      border-top: var(--white-medium) solid 1px;
      padding-top: 10px;
      cursor: pointer;
      .access {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding-top: 10px;

        p {
          margin: 0;
          color: var(--gray05);
        }
      }
    }
    .text {
      color: var(--gray05);
      margin-bottom: 25px;
    }
    .header {
      display: flex;
      gap: 10px;
      margin-bottom: 15px;
      align-items: center;
      p {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 65px;
  }
}

@media screen and (max-width: 880px) {
  .card-apps {
    padding: 0;
    clip-path: border-box;
  }
  .container-appss {
    display: grid;
    grid-template-columns: 1fr;
    gap: 45px;
  }
  .container-apps {
    justify-content: initial;
  }
}
</style>
